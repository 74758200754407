@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-block {
  @apply w-56 h-56 grid justify-items-center content-center rounded-lg mr-5 mb-5 filter drop-shadow-lg;
}

.colored-button {
  @apply bg-red-500 rounded-full px-4 py-1 cursor-pointer text-white;
}

.transparent-button {
  @apply border rounded-md border-blue-700 px-3 py-1 cursor-pointer text-blue-700;
}

.bottom-navigation {
  @apply w-11/12 h-8 flex justify-evenly items-center bg-white filter drop-shadow-lg rounded-full absolute bottom-5;
}

.screen-navigation-button {
  @apply w-8 h-8 flex justify-center items-center bg-white filter drop-shadow-lg rounded-full cursor-pointer;
}

#scroll::-webkit-scrollbar {
  display: none;
}
