.palette-color-picker {
  div {
    width: 450px;

    div {
      box-shadow: none !important;

      .compact-picker {
        margin-left: 22px !important;

        div {
          span {
            div {
              height: 29px !important;
              width: 31px !important;
              margin-bottom: 1px !important;
              margin-right: 1px !important;

              div {
                height: 5px !important;
                width: 5px !important;
                top: 12px !important;
                left: 12px !important;
              }
            }
          }
        }
      }
    }
  }
}
